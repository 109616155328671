(function () {
  'use strict';

  /* @ngdoc object
   * @name referees.referee
   * @description
   *
   */
  angular
    .module('neo.home.refereeNewInvoice.facturar',['ui.router']);
}());
